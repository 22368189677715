




























import { Component, Watch, Vue } from "vue-property-decorator";
import TankenMap from "@/components/TankenMap.vue";
import axios from "axios";
import { Station } from "@/interfaces.ts";
import StationList from "@/components/StationList.vue";
import GdprConsent from "@/components/GdprConsent.vue";
import NavBar from "@/components/NavBar.vue";
@Component({
  components: {
    TankenMap,
    StationList,
    GdprConsent,
    NavBar,
  },
})
export default class Home extends Vue {
  private TankenMap = TankenMap;
  private message = "";
  private lat = 51.163361;
  private lon = 10.447683;
  private fuelType = "diesel";
  private sortBy = "price";
  private searchRadius = 10;
  private stations: Station[] = [];
  private gdprAccepted = false;
  created() {
    const storedGdprValue = window.localStorage.getItem("gdprAccepted");
    if (storedGdprValue && storedGdprValue === "yes") {
      this.gdprAccepted = true;
      const sortBy = window.localStorage.getItem("sortBy");
      const fuelType = window.localStorage.getItem("fuelType");
      const searchRadius = parseInt(
        window.localStorage.getItem("searchRadius") ?? "0"
      );
      this.sortBy = sortBy || this.sortBy;
      this.fuelType = fuelType || this.fuelType;
      this.searchRadius = searchRadius || this.searchRadius;
    }
  }
  setSortBy(val: string) {
    this.sortBy = val;
    window.localStorage.setItem("sortBy", val);
  }
  setFuelType(val: string) {
    this.fuelType = val;
    window.localStorage.setItem("fuelType", val);
  }
  setSearchRadius(val: number) {
    this.searchRadius = val;
    window.localStorage.setItem("searchRadius", val.toString());
  }
  gdprRejected() {
    window.localStorage.removeItem("gdprAccepted");
    window.localStorage.removeItem("fuelType");
    window.localStorage.removeItem("searchRadius");
    window.localStorage.removeItem("sortBy");
    this.gdprAccepted = false;
  }
  acceptGdpr() {
    window.localStorage.setItem("gdprAccepted", "yes");
    this.gdprAccepted = true;
  }
  @Watch("gdprAccepted")
  getPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.showPosition,
        this.showPositionError,
        { maximumAge: 50000, timeout: 15000, enableHighAccuracy: true }
      );
    } else {
      this.message =
        "Die Standortfreigabe ist erforderlich für die Applikation, von Ihrem Browser aber nicht unterstützt oder blockiert.";
    }
  }
  showPosition(position: any) {
    this.lat = position.coords.latitude;
    this.lon = position.coords.longitude;
  }
  showPositionError(error: any) {
    console.log("getPositionError", error);
    this.message = `Leider hat die Standortfreigabe nicht geklappt: ${error.message}. Versuchen Sie die Seite neu zu laden oder korrigieren Sie Ihre Browsereinstellungen.`;
  }
  @Watch("lat")
  @Watch("fuelType")
  @Watch("sortBy")
  @Watch("searchRadius")
  updateStations() {
    this.fetchStations(undefined);
  }
  fetchStations(coords: any) {
    const lat = coords?.lat || this.lat;
    const lng = coords?.lng || this.lon;
    axios
      .get(
        `${process.env.VUE_APP_API}/stations?lat=${lat}&lon=${lng}&type=${this.fuelType}&sort=${this.sortBy}&radius=${this.searchRadius}`,
        { timeout: 5000 }
      )
      .then((res: any) => {
        if (res.data.success) {
          this.stations = res.data.stations.filter((station: Station) => {
            return station.isOpen;
          });
        } else {
          console.log("Request failed", res);
        }
      });
  }
}
