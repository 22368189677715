














































import { Component, Prop, Vue } from "vue-property-decorator";
import { Station } from "@/interfaces.ts";

@Component
export default class StationList extends Vue {
  @Prop() private stations!: Station[];
  mounted() {
    this.$root.$on("station-map-click", this.showStation);
  }
  resetGdpr() {
    this.$emit("reject-gdpr");
  }
  clickStation(station: Station) {
    this.$root.$emit("station-list-click", station);
  }
  showStation(station: Station) {
    console.log("@todo showing in list:", station.id);
  }
}
