var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',{ref:"map",attrs:{"id":"map","center":{ lat: this.lat, lng: this.lng },"zoom":_vm.zoom,"map-type-id":"terrain","options":{
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: false,
    disableDefaultUi: false,
  }},on:{"zoom_changed":_vm.setZoom}},[_c('poi',{attrs:{"lat":this.lat,"lng":this.lng,"text":"You!","self":""}}),_vm._l((_vm.stations),function(station,index){return _c('poi',{key:("station-marker-" + index),attrs:{"lat":station.lat,"lng":station.lng,"text":station.price,"priceTag":station.price < _vm.avgPrice && index < 15
        ? 'low'
        : station.price >= _vm.avgPrice
        ? 'high'
        : 'med'},on:{"custom-marker-clicked":function($event){return _vm.getStationDetail(station)}}})}),(_vm.stationDetail)?_c('GmapInfoWindow',{attrs:{"opened":_vm.infoOpen,"position":{ lat: _vm.stationDetail.lat, lng: _vm.stationDetail.lng }},on:{"closeclick":function($event){_vm.infoOpen = false}}},[_vm._v(" Diesel: "+_vm._s(_vm.stationDetail.diesel)),_c('br'),_vm._v(" Super: "+_vm._s(_vm.stationDetail.e5)),_c('br'),_vm._v(" E10: "+_vm._s(_vm.stationDetail.e10)),_c('br'),_c('br'),_c('a',{staticClass:"brand waze",attrs:{"href":("https://waze.com/ul?ll=" + (_vm.stationDetail.lat) + "," + (_vm.stationDetail.lng) + "&navigate=yes"),"target":"_blank"}},[_vm._v("Waze")]),_c('a',{staticClass:"brand maps",attrs:{"href":("https://www.google.com/maps/dir/?api=1&destination=" + (_vm.stationDetail.lat) + "," + (_vm.stationDetail.lng) + "&travelmode=car"),"target":"_blank"}},[_vm._v("Google")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }