var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gmap-custom-marker',{attrs:{"marker":{ lat: _vm.lat, lng: _vm.lng }}},[_c('div',{class:{
      'poi-wrapper': true,
      'poi-self': _vm.self,
      'price-low': _vm.priceTag === 'low',
      'price-med': _vm.priceTag === 'med',
      'price-high': _vm.priceTag === 'high',
      'near-low': _vm.nearAndLow,
    },on:{"click":_vm.clickAction}},[_c('div',{staticClass:"pin"}),_c('div',{staticClass:"pulse"}),_c('div',{staticClass:"poi"},[_vm._v(_vm._s(_vm.text))])])])}
var staticRenderFns = []

export { render, staticRenderFns }