










































import Vue from "vue";
import PrivacyText from "@/components/PrivacyText.vue";
import ImprintText from "@/components/ImprintText.vue";

export default Vue.extend({
  data() {
    return {
      accepted: false,
      rejected: false,
      showPrivacy: false,
      showImprint: false,
    };
  },
  components: {
    PrivacyText,
    ImprintText,
  },
  methods: {
    agree() {
      this.accepted = true;
      this.rejected = false;
      this.$emit("gdpr-accepted");
    },
    decline() {
      this.accepted = false;
      this.rejected = true;
      this.$emit("gdpr-declined");
    },
  },
});
