




























































































import { Component, Prop, Vue } from "vue-property-decorator";
type FuelType = string;
type SearchRadius = number;
type SortBy = string;

@Component
export default class TankenMap extends Vue {
  @Prop() private fuelType!: FuelType;
  @Prop() private searchRadius!: SearchRadius;
  @Prop() private sortBy!: SortBy;

  emitEvent(name: string, value: string | number) {
    this.$emit(name, value);
  }
  updatePosition() {
    this.$root.$emit("map-get-center");
  }
}
