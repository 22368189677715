


























































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { Station, StationDetail } from "@/interfaces.ts";
import Poi from "@/components/Poi.vue";
import axios from "axios";

@Component({
  components: {
    Poi,
  },
  computed: {
    avgPrice(): number {
      const sum = this.$props.stations.reduce((a: Station, b: Station) => ({
        price: a.price + b.price,
      }));
      const avg = sum.price / this.$props.stations.length;
      return avg;
    },
  },
})
export default class TankenMap extends Vue {
  @Prop() private lat!: number;
  @Prop() private lng!: number;
  @Prop() private stations!: Station[];
  @Prop() private type!: string;
  private stationColor = "#ff0000";
  private zoom = 13;
  private map: any = null;
  private stationDetail: StationDetail | null = null;
  private infoOpen = false;
  mounted() {
    this.$root.$on("station-list-click", this.getStationDetail);
    this.$root.$on("map-get-center", this.emitCoords);
    const map = this.$refs.map as any;
    map.$mapPromise.then((map: any) => {
      this.map = map;
    });
  }
  setZoom(zoom: number) {
    zoom;
    this.zoom = zoom;
  }
  zoomTo(station: Station) {
    this.map.panTo({ lat: station.lat, lng: station.lng });
  }
  async emitCoords() {
    const coords = this.map.getCenter();
    this.$emit("map-coords", { lat: coords.lat(), lng: coords.lng() });
  }
  getStationDetail(station: Station) {
    this.$root.$emit("station-map-click", station);
    this.map.panTo({ lat: station.lat, lng: station.lng });
    axios
      .get(`${process.env.VUE_APP_API}/stations/${station.id}`)
      .then((res: any) => {
        this.infoOpen = true;
        this.stationDetail = res.data.station;
      });
  }
}
